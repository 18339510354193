var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"credit-card"},[_c('h2',{staticClass:"credit-card__title"},[_vm._v("ACH")]),_c('div',{staticClass:"stripe-card__row stripe-card__row-two-col"},[_c('div',{staticClass:"stripe-card__number-row"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localAchName),expression:"localAchName"}],staticClass:"cardknox-ach-fields",attrs:{"type":"text","name":"ach-name","id":"achname","placeholder":"Name"},domProps:{"value":(_vm.localAchName)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.localAchName=$event.target.value},_vm.onAchNameInputChange]}})])]),_c('div',{staticClass:"stripe-card__row stripe-card__row-two-col"},[_c('div',{staticClass:"stripe-card__number-row"},[_c('ifields',{ref:"ifieldACHRef",attrs:{"type":_vm.ACH_TYPE,"account":_vm.iFieldsOptions,"options":{
          autoFormat: true,
          autoFormatSeparator: ' ',
          autoSubmit: false,
          placeholder: 'Account Number',
          iFieldstyle: {
            fontSize: '16px',
            backgroundColor: '#EDEDED',
            color: _vm.backgroundColor,
            border: 'none',
            outline: 'none',
          }
        }}})],1)]),_c('div',{staticClass:"stripe-card__row stripe-card__row-two-col"},[_c('div',{staticClass:"stripe-card__number-row"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localAchRouting),expression:"localAchRouting"}],staticClass:"cardknox-ach-fields",attrs:{"type":"text","name":"ach-routing","id":"achRouting","inputmode":"numeric","placeholder":"Routing Number"},domProps:{"value":(_vm.localAchRouting)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.localAchRouting=$event.target.value},_vm.onAchRoutingInputChange]}})])]),(_vm.hasCardError)?_c('div',{staticClass:"form__error credit-card__error",attrs:{"id":'payment-message-' + _vm.cardknoxItemID}},[(_vm.errorNumber)?_c('div',[_vm._v(" "+_vm._s(_vm.errorNumber)+" ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"form__error credit-card__error",attrs:{"id":'card-error-' + _vm.cardknoxItemID}})])}
var staticRenderFns = []

export { render, staticRenderFns }