<template>
  <div
    class="credit-card"
  >
    <h2 class="credit-card__title">Credit Card</h2>
    <div class="stripe-card__row stripe-card__row-two-col">
      <div class="stripe-card__number-row">
        <div
          :id="'card-number-' + stripeItemID"
          class="stripe-card__number"
        />
      </div>
      <div
        class="stripe-card__row-right-col"
      >
        <div
          :id="'card-expiry-' + stripeItemID"
          class="stripe-card__date"
        />
        <div
          :id="'card-cvc-' + stripeItemID"
          class="stripe-card__cvc"
        />
      </div>
    </div>
    <div
      :id="'payment-message-' + stripeItemID"
      v-if="hasCardError"
      class="form__error credit-card__error"
    >
      <div v-if="errorNumber">
        {{ errorNumber }}
      </div>
      <div v-if="errorCvc">
        {{ errorCvc }}
      </div>
      <div v-if="errorExpiry">
        {{ errorExpiry }}
      </div>
    </div>
    <div
      :id="'card-error-' + stripeItemID"
      class="form__error credit-card__error"
    />
  </div>
</template>

<script>


export default {
  props: {
    errorNumber: {
      type: String,
      default: '',
    },
    errorCvc: {
      type: String,
      default: '',
    },
    errorExpiry: {
      type: String,
      default: '',
    },
    stripeItemID: {
      type: String,
      default: '',
    },
    hasCardError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss">

</style>
