<template>
  <div
    class="credit-card"
  >
    <h2 class="credit-card__title">Credit Card</h2>
    <div class="stripe-card__row stripe-card__row-two-col">
      <div class="stripe-card__number-row">
        <ifields
          ref="ifieldCardRef"
          :type="CARD_TYPE"
          :account=iFieldsOptions
          :options="{
            autoFormat: true,
            autoFormatSeparator: ' ',
            autoSubmit: false,
            placeholder: '1234 1234 1234 1234',
            iFieldstyle: {
              fontSize: '16px',
              backgroundColor: '#EDEDED',
              color: backgroundColor,
              border: 'none',
              outline: 'none',
            }
          }"
        />
      </div>
      <div
        class="stripe-card__row-right-col"
      >
        <input class="stripe-card__date cardknox-expiry"
               type="text"
               name="exp-date"
               inputmode="numeric"
               maxlength="5"
               placeholder="MM / YY"
               v-model="localExpiry"
               id="expiryDate"
               v-mask="'##/##'"
               :masked="true"
               @input="onCardExpiryInputChange"
        >
      </div>
    </div>
    <div
      :id="'payment-message-' + cardknoxItemID"
      v-if="hasCardError"
      class="form__error credit-card__error"
    >
      <div v-if="errorNumber">
        {{ errorNumber }}
      </div>
      <div v-if="errorExpiry">
        {{ errorExpiry }}
      </div>
    </div>
    <div
      :id="'card-error-' + cardknoxItemID"
      class="form__error credit-card__error"
    />
  </div>
</template>

<script>

import ifields, { CARD_TYPE } from '@cardknox/vue-cardknox-ifields'

export default {
  components: {
    ifields,
  },
  computed: {
    backgroundColor() {
      return process.env.SECONDARY_COLOR;
    }
  },
  props: {
    iFieldsOptions: {
      type: Object,
      required: true,
    },
    errorNumber: {
      type: String,
      default: '',
    },
    errorExpiry: {
      type: String,
      default: '',
    },
    hasCardError: {
      type: Boolean,
      default: false,
    },
    cardknoxItemID: {
      type: String,
      default: '',
    },
    cardExpiry: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      localExpiry: this.cardExpiry,
      CARD_TYPE,
    }
  },
  methods: {
    onCardExpiryInputChange(event) {
      this.$emit('updateExpire', event.target.value)
    }
  },
}
</script>


<style lang="scss">
iframe {
  border: 0 solid transparent;
  height: 45px;
  padding: 0px;
  margin-bottom: 5px;
}

.cardknox-expiry{
  border: none;
  outline: none;
  font-size: 16px;
  background-color: #EDEDED;
  padding: 0px;
}
</style>
