<template>
  <div
    class="credit-card"
  >
    <h2 class="credit-card__title">ACH</h2>
    <div class="stripe-card__row stripe-card__row-two-col">
      <div class="stripe-card__number-row">
        <input
          class="cardknox-ach-fields"
          type="text"
          name="ach-name"
          id="achname"
          placeholder="Name"
          v-model="localAchName"
          @input="onAchNameInputChange"
        />
      </div>
    </div>
    <div class="stripe-card__row stripe-card__row-two-col">
      <div class="stripe-card__number-row">
        <ifields
          ref="ifieldACHRef"
          :type="ACH_TYPE"
          :account=iFieldsOptions
          :options="{
            autoFormat: true,
            autoFormatSeparator: ' ',
            autoSubmit: false,
            placeholder: 'Account Number',
            iFieldstyle: {
              fontSize: '16px',
              backgroundColor: '#EDEDED',
              color: backgroundColor,
              border: 'none',
              outline: 'none',
            }
          }"
        />
      </div>
    </div>
    <div class="stripe-card__row stripe-card__row-two-col">
      <div class="stripe-card__number-row">
        <input
          class="cardknox-ach-fields"
          type="text"
          name="ach-routing"
          id="achRouting"
          inputmode="numeric"
          placeholder="Routing Number"
          v-model="localAchRouting"
          @input="onAchRoutingInputChange"
        />
      </div>
    </div>
    <div
      :id="'payment-message-' + cardknoxItemID"
      v-if="hasCardError"
      class="form__error credit-card__error"
    >
      <div v-if="errorNumber">
        {{ errorNumber }}
      </div>
    </div>
    <div
      :id="'card-error-' + cardknoxItemID"
      class="form__error credit-card__error"
    />
  </div>
</template>

<script>

import ifields, { ACH_TYPE } from '@cardknox/vue-cardknox-ifields'

export default {
  components: {
    ifields,
  },
  computed: {
    backgroundColor() {
      return process.env.SECONDARY_COLOR;
    }
  },
  props: {
    iFieldsOptions: {
      type: Object,
      required: true,
    },
    errorNumber: {
      type: String,
      default: '',
    },
    hasCardError: {
      type: Boolean,
      default: false,
    },
    cardknoxItemID: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      ACH_TYPE,
      localAchRouting: null,
      localAchName: null,
    }
  },
  methods: {
    onAchRoutingInputChange(event) {
      this.$emit('updateAchRouting', event.target.value)
    },
    onAchNameInputChange(event) {
      this.$emit('updateAchName', event.target.value)
    },
  },
}
</script>


<style lang="scss">
iframe {
  border: 0 solid transparent;
  height: 45px;
  padding: 0px;
  margin-bottom: 5px;
}

.cardknox-ach-fields{
  border: none;
  outline: none;
  font-size: 18px;
  background-color: #EDEDED;
  padding: 0px;
}

</style>
