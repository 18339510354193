<template>
  <div>
    <div class="application__title">
      <h2>{{ paymentContract.name }}</h2>
    </div>
    <p class="application__subtitle">Select payment contract options</p>

    <PaymentContractOption
      v-for="(option, index) in paymentContract.options"
      :key="index"
      :selected-option="selectedOption"
      :option="option"
      :order="index + 1"
      :disabled="disabledPaymentContract"
      :total-sum="totalSum"
      @selectOption="selectOption"
      @selectMethod="selectMethod"
      @optionValidation="selectedOptionValidation"
    />

    <div v-if="paymentContract.selectedOption">
      <h3 class="payment-details__title">Adding payment details</h3>

      <div
        class="payment-details"
        v-if="!hidePriceBreakdown"
      >
        <div class="payment-details__header">
          <div>Charge</div>
          <div>Amount ($)</div>
        </div>
        <div
          class="payment-details__row"
          v-for="session in selectedSessionsWithoutZeros"
          :key="session.id"
        >
          <div>{{ session.name }}</div>
          <div>{{ session.price.toFixed(2) }}</div>
        </div>
        <div
          class="payment-details__row"
          v-for="group in groupsForPC"
          :key="group.id"
        >
          <div>{{ group.name }}</div>
          <div>{{ group.price.toFixed(2) }}</div>
        </div>

        <div
          v-if="discount > 0"
          class="payment-details__row"
        >
          <div>{{ discountName }}</div>
          <div>- {{ discount }}</div>
        </div>

        <div
          v-if="additionalCharge > 0"
          class="payment-details__row"
        >
          <div>{{ additionalChargeName }}</div>
          <div>{{ additionalCharge }}</div>
        </div>

        <div
          v-if="paidAmountForPc > 0"
          class="payment-details__row"
        >
          <div>Paid</div>
          <div>- {{ paidAmountForPc.toFixed(2) }}</div>
        </div>

        <div class="payment-details__total breakdown">
          <div>Total Tuition and Fees</div>
          <div>{{ totalWithDiscount }}</div>
        </div>
      </div>
      <div
        class="payment-details"
        v-else
      >
        <div class="payment-details__total">
          <div>Total Tuition and Fees</div>
          <div>{{ totalWithDiscount }}</div>
        </div>
      </div>
    </div>

    <p class="form__error" >{{ errorMessage }}</p>
  </div>
</template>

<script>
import Vue from 'vue'
import PaymentContractOption from '@/components/site/PaymentContractOption.vue'
import store from '@/store'
import { mapGetters } from 'vuex'

export default {
  components: {
    PaymentContractOption,
  },
  props: {
    hidePriceBreakdown: {
      type: Boolean,
      default: false,
    },
    stepStatus: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedOption: null,
      selectedMethod: null,
      validTracker: false,
      errorMessage: "",
      isLoading: false,
      isSaveButtonDisabled: false,
      isSendButtonDisabled: false,
      optionInvalid: false,
      groupsForPC: [],
    };
  },
  watch: {
    paymentContract(val) {
      if (val.selectedOption) {
        this.selectedOption = val.selectedOption
      }
    },
    selectedGroupsForPC() {
      this.prepareGroupsForPCData()
    },
    groupsForAutoAssign() {
      this.prepareGroupsForPCData()
    },
  },
  mounted() {
    this.prepareAutoAssignedGroups()
  },
  computed: {
    ...mapGetters({
      applicationId: 'applicationRegistration/getApplicationId',
      selectedSessions: 'applicationRegistration/getSelectedSessionsList',
      paymentContractIsExist: 'applicationRegistration/getPaymentContractIsExist',
      paymentContract: 'applicationRegistration/getPaymentContract',
      programId: 'applicationRegistration/getProgramID',
      selectedGroups: 'applicationRegistration/getAllSelectedGroups',
      selectedGroupsForPC: 'applicationRegistration/getSelectedGroupsForPC',
      groupsForAutoAssign: 'applicationRegistration/getGroupsForAutoAssign',
      paidAmountForPc: 'applicationRegistration/getPaidAmountForPc',
    }),
    selectedSessionsWithoutZeros() {
      return this.selectedSessions.filter(session => session.price != 0)
    },
    totalSum() {
      let result = 0
      this.selectedSessions.forEach(session => {
        result += session.price
      })
      this.groupsForPC.forEach(group => {
        result += group.price
      })

      if (this.paidAmountForPc > 0) {
        result -= this.paidAmountForPc
      }
      return result
    },
    totalWithDiscount() {
      return (Number(this.totalSum) - Number(this.discount) + Number(this.additionalCharge)).toFixed(2)
    },
    additionalCharge() {
      const selectedoption = this.paymentContract.options.find(option => option.id === this.selectedOption)

      let amount = 0
      if (selectedoption)
        if (selectedoption.price_option.priceType == 'Full Price + Additional Charge') {
          amount = Number(selectedoption.price_option.additionalCharge)
        }
      return amount.toFixed(2)
    },
    additionalChargeName() {
      const selectedoption = this.paymentContract.options.find(option => option.id === this.selectedOption)

      if (selectedoption
        && selectedoption.price_option.priceType == 'Full Price + Additional Charge'
        && selectedoption.price_option.chargeName
      ) {
        return selectedoption.price_option.chargeName
      }
      return 'Additional Charge'
    },
    discount() {
      const selectedoption = this.paymentContract.options.find(option => option.id === this.selectedOption)

      let amount = 0
      if (selectedoption)
      if (selectedoption.price_option.priceType == 'Percent discount') {
        amount = this.totalSum * selectedoption.price_option.percentDiscount / 100
      } else if (selectedoption.price_option.priceType == 'Dollar discount') {
        amount = Number(selectedoption.price_option.dollarDiscount)
      }
      return amount.toFixed(2)
    },
    discountName() {
      const selectedoption = this.paymentContract.options.find(option => option.id === this.selectedOption)

      if (selectedoption
        && selectedoption.price_option.priceType == 'Dollar discount'
        && selectedoption.price_option.discountName
      ) {
        return selectedoption.price_option.discountName
      }
      return 'Discount'
    },
    disabledPaymentContract() {
      return !!(this.paymentContractIsExist && this.stepStatus) || this.paymentContract.options.some(item => item.defaults)
    }
  },
  methods: {
    prepareGroupsForPCData() {
      this.groupsForPC = []
      this.selectedGroupsForPC.filter(group => group.price != 0).forEach(group => {
        this.groupsForPC.push(group)
      })
      this.groupsForAutoAssign.filter(group => group.price != 0).forEach(newGroup => {
        const group = this.groupsForPC.find(group => group.id === newGroup.id)
          if (!group) {
            this.groupsForPC.push(newGroup)
          }
      })
    },
    selectOption(option) {
      this.selectedOption = option.id
      this.paymentContract.selectedOption = option.id
      this.selectMethod(option.selectedMethod)
    },
    selectMethod(val) {
      this.$emit('viewableCC', val !== 'Credit Card')
      this.$emit('viewableACH', val !== 'ACH')
    },
    selectedOptionValidation(data) {
      if (this.paymentContract.selectedOption === data.id) {
        Vue.set(this.paymentContract, 'optionInvalid', data.hasError)
      }
    },
    prevTab() {
      this.$emit('prevTab')
    },
    async prepareAutoAssignedGroups() {
      if (this.applicationId) {
        await store.dispatch('applicationRegistration/fetchAutoAssignedGroupsList', {
          application_id: this.applicationId,
        })
      }
    },
  },
};
</script>
